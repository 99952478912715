$breakpoints: (
  phoneSmall: 375px,
  phone: 450px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.PromoTile {
  position: relative;
  overflow: hidden;
  border-radius: $radius;
  display: flex;
  flex-direction: column;
  user-select: none;

  > figure {
    min-height: 200px;
    position: relative;
    display: none;
    flex: 1;
  }

  > section {
    padding: $spacing;
    display: flex;
    flex-direction: column;
  }

  h3,
  p {
    margin-bottom: $spacing;
  }

  h3 {
    font-weight: bold;
    font-size: 22px;
  }

  p {
    font-size: 12px;
  }

  img {
    object-fit: cover;
  }
  
  .Button {
    margin-top: auto;
    margin-right: auto;
    width: 100%;
  }

  > figure {
    display: block;
  }

  flex-direction: row;

  > figure {
    order: 2;

    img {
      &:nth-child(1) {
        width: 58px;
        height: auto;
        margin: auto;
      }
      &:nth-child(2) {
        display: none;
        height: 138px;
        width: auto;
        margin: auto;
      }
    }
  }

  @media screen and (max-width: 767px) {
    > figure,
    > section {
      min-width: 50%;
      max-width: 50%;
    }
  }
    
  @media screen and (min-width: 768px) {
    .Button {
      min-width: 200px;
      max-width: 200px;
    }

    p {
      font-weight: 600;
    }

    > section {
      min-width: $dailyTileMediaWidth;
      max-width: $dailyTileMediaWidth;
    }

    > figure {
      img {
        &:nth-child(1) {
          display: none;
        }
        &:nth-child(2) {
          display: block;
        }
      }
    }
  }
}
